import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;
`;

export const PrivacyPolicy = (): React.ReactElement => {
  return (
    <Container>
      <h2>WFP Online Auctions</h2>
      <h3>Privacy Policy</h3>
      <p>
        This Privacy Policy governs the collection, use, and disclosure of
        personal data by the World Food Programme online auction tool (“the
        Platform”), a Global Shared Service powered by the World Food Programme
        ("WFP") where UN, NGOs, governmental, humanitarian, and development
        parties can participate in an auction.
      </p>

      <h3>Introduction</h3>
      <p>
        WFP is committed to protecting the privacy and confidentiality of its
        users' personal data. This policy outlines how we collect, use, and
        safeguard the personal data provided by users when accessing and
        utilizing the Platform.
      </p>

      <h3>Collection and use of personal data</h3>
      <p>
        WFP, Via Cesare Giulio Viola 68/70 – 00148 Roma, Italy, is the data
        controller of the personal data provided on the Platform. Browsing on
        the Platform does not require the User to provide personal data to WFP.
        However, Users must register with the Platform to make an offer, to bid
        for WFP items or lots.
      </p>
      <p>WFP, as a data controller, will process:</p>
      <p>
        (i) the user’s identification and contact details to manage his/her
        registration with the Platform and, once he/she has made an offer or
        bid, to inform him/her on the bid status; and
      </p>
      <p>
        (ii) the information linked to an offer or bid to execute the auction
        process and to decide their allocation.
      </p>
      <p>
        By accessing the Platform, certain information such as Internet protocol
        (IP) addresses, navigation through the Platform, the browser used, and
        the time spent, along with other similar information, will be stored on
        our servers and on a third-party analytics software. This information is
        collected through cookies or similar technologies and is processed to
        better understand the preferences of the Platform’s visitors and improve
        their experience. This information is never connected with any direct
        identifier that visitors supply to us. Users can use their browsing
        settings to deny the use of cookies and similar technologies that are
        not essential for the Platform to work properly.
      </p>

      <h3>Retention</h3>
      <p>
        WFP will keep the personal data for as long as it is strictly necessary
        according to our internal regulations and rules and, in any event, for a
        maximum period of 6 years After this period, the personal data will
        either be deleted or anonymized.
      </p>

      <h3>Third-Party Disclosure</h3>
      <p>
        WFP does not disclose personal information to third parties, except as
        strictly necessary to facilitate the requested services and in
        accordance with the above-mentioned purposes (for example, technology
        providers supporting the Platform).
      </p>

      <h3>Changes to the Privacy Policy</h3>
      <p>
        The Platform’s Privacy Policy may be updated from time to time. It is
        advisable to review this policy periodically to stay informed about how
        personal data is being protected.
      </p>

      <h3>Privileges and Immunities</h3>
      <p>
        Nothing in or relating to this privacy policy or this service shall be
        deemed a waiver of any of the privileges and immunities of the WFP under
        the Convention on the Privileges and Immunities of the United Nations
        and the Convention on the Privileges and Immunities of the Specialized
        Agencies.
      </p>

      <h3>Data Protection Rights and Contact</h3>
      <p>
        Questions and/or requests to access, rectify or delete the personal
        data, object to a processing activity, can be sent to
        support.auctiondigitaloffice@wfp. org
      </p>
    </Container>
  );
};
