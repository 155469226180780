import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;

  a {
    color: #858bff;
  }
`;

export const TermsAndConditions = () => {
  return (
    <Container>
      <h1>Terms of Use The World Food Programme Online Auctions</h1>
      <p>
        These terms of use ("Terms") shall govern the use of and participation
        to the World Food Programme online auction tool ("Platform") between the
        user of the Platform (“User”) and the World Food Programme (“WFP”). By
        accessing or using the Platform and its services, you agree to comply
        with and be bound by these Terms.
      </p>

      <h2>1. Definitions</h2>
      <p>
        "Auction" refers to the process by which the Users place bids on items
        listed on the Platform.
      </p>
      <p>
        "Bidder" refers to a User who places Bids on Lots listed on the
        Platform, who is over 18 years of age and able of entering into legally
        binding contracts.
      </p>
      <p>
        “Increase step” or “Bidding step” refers to a predefined minimal
        increase in Bid amount that the Bidder can make to place a Bid for a
        specific Lot. “Lot” refers to an individual item or group of items
        offered for auction by WFP on the Platform. It encompasses all
        information, including but not limited to text descriptions, images,
        media, starting bid amounts, Reserve Prices, duration, and any
        additional terms or conditions specified by WFP which are presented on
        the Platform in relation to the Auction.
      </p>
      <p>
        “Offer” or “Bid” refers to a bid or proposal made by a Bidder on the
        Platform to purchase a Lot for auction by WFP.
      </p>
      <p>
        "Platform" refers to the World Food Programme Online Auctions tool
        mobile application
      </p>
      <p>
        “Reserve Price” refers to a predefined and undisclosed minimal bid
        amount for which WFP can seal a Lot.
      </p>
      <p>
        “Requirements” refer to any legal or regulatory requirements, including
        but not limited to licenses, permits, or registrations which certain
        Items auctioned on the Platform may be subject to.
      </p>
      <p>
        “User” refers to the individual or entity making use of the Platform.
      </p>
      <p>
        "User Account" refers to the account belonging to User utilised to
        access the Platform
      </p>
      <p>
        “Winning Bid” refers to the Bid identified by WFP as the Bid awarded the
        Lot.
      </p>
      <p>
        "Winning Bidder" refers to the Bidder who places the Winning Bid
        accepted by WFP.
      </p>
      <p>
        “WFP Marks” refers to the name, emblem and abbreviation of the World
        Food Programme.
      </p>

      <h2>2. Platform Usage</h2>
      <p>
        2.1. Internal rules and User Account: The User shall be subject to all
        applicable WFP rules and regulation for with regard to internal use of
        WFP online recourses. To access the services of the Platform, User must
        use their corporate WFP email address and credentials. User agrees to
        only access the Platform using its own corporate account. Nothing in
        these Terms of Use shall be deemed to confer any third party rights or
        benefits.
      </p>
      <p>
        2.2. Suspension and termination: WFP may reserve the right to suspend,
        or terminate User access at its discretion, including for violations of
        these Terms, in particular in case where User places Bids without the
        intend to purchase and collect Lots within defined time or for any other
        reason. The User’s rights and obligations resulting from an Offer
        submitted in relation to a Lot shall remain unaffected by any
        modification, suspension or termination of User Account.
      </p>

      <h2>3. Participation to bid</h2>
      <p>
        3.1. WFP Invitation to Bid: WFP’s display or make available of items and
        Lots on the Platform does not constitute a binding or enforceable offer
        by WFP or contract between WFP and the Bidder. It is a non-binding
        request for offer with no obligation for WFP to accept any Offer made
        following the Invitation to bid, even if such Offer is fully matching
        all requirements of the invitation to bid. Regarding the award of the
        Lot, all Lots for auction are subject to a Reserve Price set by WFP. WFP
        is not obligated to disclose the Reserve Price and reserves the right to
        reject any Bids and refrain from award of a Lot if the Bid does not meet
        the Reserve Price.
      </p>
      <p>
        3.2. Cancellation of Listings: WFP reserves the right to cancel at any
        time the Listing and to withdraw the award in whole or in part for any
        reason, including in the case where the Winning Bidder is unable to
        provide full payment within 5 workdays after the end of the Auction.
      </p>
      <p>
        3.3. Bidder Offer: The Bidder agrees an acknowledges that an Offer
        constitutes a legally binding commitment from the side of the Bidder to
        purchase the Lot, if their Bid is identified by WFP as Winning Bid as
        determined by the rules and procedures of the auction conducted on the
        Platform. The Offer made by the Bidder shall signify the specific
        monetary amount that the Bidder offers to pay for the Item. For the
        avoidance of doubt, any additional fees applied by Bidder’s bank, taxes,
        and any other related charges shall be borne solely by the Bidder and
        shall constitute its sole responsibility.
      </p>

      <h2>4. Transactions</h2>
      <p>
        4.1. Payment Instructions: Upon award the Winning Bidder shall be
        informed by means of an email to the User’s email address, such email
        shall include relevant payment instructions. Usage of the Platform is
        free of charge. Any payment to WFP shall be made within 5 days from the
        date of award the by means of a bank transfer. Any charges applied by
        the bank in relation to this transfer shall be covered by the Bidder.
      </p>
      <p>
        4.2. Delivery: Once payment is received, WFP shall inform the Winning
        Bidder of the Pick-up instructions. Therefore, WFP shall make the Item
        available and accessible to the Winning Bidder for pick-up at the WFP
        premises.
      </p>
      <p>
        4.3. Returns and Refunds: WFP does not accept the return of any Item and
        is not liable for any refund to the Winning Bidder.
      </p>
      <p>
        4.4. Bidder due diligence: The Winning Bidder acknowledges that certain
        items auctioned on the Platform may be subject to legal or regulatory
        requirements, including but not limited to licenses, permits, or
        registrations (“Requirements”) that must be obtained for lawful
        possession, use, or ownership of the item. It is the Winning Bidder's
        sole responsibility to research and identify any applicable Requirements
        associated with an item before placing a Bid. By participating in the
        Auction, the Winning Bidder confirms that they have conducted the
        necessary due diligence to determine if any Requirements apply to the
        item they a bidding for or which they have acquired.
      </p>

      <h2>5. Liability</h2>
      <p>
        5.1. Limited Warranty and Disclaimers: WFP hereby expressly disclaims
        and informs that items for auction are provided "as-is" and
        "as-available," without any express or implied warranties of any kind.
        This includes, but is not limited to, warranties of merchantability,
        fitness for a particular purpose, or non-infringement.
      </p>
      <p>
        WFP shall not be held liable for the condition, quality, authenticity,
        legality, or suitability of items listed for auction. To the fullest
        extent permitted by law, WFP shall not be liable for any direct,
        indirect, incidental, consequential, special, or punitive damages, or
        any loss of profits or revenues, whether incurred directly or
        indirectly, goodwill, or other intangible losses, arising out of use of
        any of the items acquired by the User and any transactions conducted on
        the Platform.
      </p>
      <p>
        5.2. Platform Disclaimer: The use of the Platform, and any of the
        features is at the User’s sole risk. The Platform, its content and
        features are provided “as is” and “as available", without warranty of
        any kind, either express or implied, including, without limitation,
        warranties of merchantability, fitness for a particular purpose and
        non-infringement. WFP specifically does not make any warranties or
        representations as to the accuracy or completeness of the information
        presented on the Platform. WFP periodically adds, changes, improves or
        updates the Platform, materials and the platform features without
        notice. The User specifically acknowledges and agrees that WFP is not
        liable for the conduct of any User.
      </p>

      <h2>6. Data protection</h2>
      <p>
        The use of some services available on the Platform may require the
        processing of personal data. WFP will process personal data according to
        its own data protection and privacy regulations and as outlined in the
        Privacy Policy.
      </p>

      <h2>7. Dispute Resolution</h2>
      <p>
        7.1. These Terms of Use and any dispute arising therefrom shall be
        exclusively governed by international principles of international law,
        to the exclusion of any single national system of law.
      </p>
      <p>
        7.2. Any dispute, controversy or claim arising out of or relating to
        these Terms of Use, or the breach, termination or invalidity hereof or
        to the use of the Sites, the Materials and Site Features by the User or
        any content available therein and their particular terms of use shall be
        settled by Arbitration in accordance with the UNCITRAL Arbitration Rules
        then in force. The arbitral tribunal, which shall be composed of 1 (one)
        arbitrator, shall be empowered to order: (i) the return or destruction
        of goods or any property, whether tangible or intangible, or of any
        confidential information provided hereunder; (ii) the termination
        hereof; or (iii) that any other protective measures be taken with
        respect to the goods, services or any other property, whether tangible
        or intangible, or of any confidential information provided hereunder, as
        appropriate, all in accordance with the authority of the arbitral
        tribunal pursuant to Article 26 (‘the Interim Measures’) and Article 34
        (‘the Form and Effect of the Award’) of the UNCITRAL Arbitration Rules.
        The arbitral tribunal shall have no authority to award punitive damages.
        WFP and the User shall be bound by any arbitration award rendered as a
        result of such arbitration as the final adjudication of any such
        Dispute. The language of any such proceedings shall be English and the
        location shall be Rome.
      </p>

      <h2>8. Preservation of Immunities</h2>
      <p>
        Nothing in these Terms of Use shall imply a waiver, express or implied,
        by WFP, the United Nations and the Food and Agriculture Organization of
        the United Nations of any privileges and immunities enjoyed by them
        pursuant to the 1946 Convention on the Privileges and Immunities of the
        United Nations, the 1947 Convention on the Privileges and Immunities of
        Specialized
      </p>
      <p>
        Agencies, customary international law, other relevant international or
        national agreements, and under domestic law.
      </p>

      <h2>9. Miscellaneous</h2>
      <p>
        9.1. Amendments to the Terms: WFP reserves its exclusive right in its
        sole discretion to (i) alter, limit or discontinue the access to the
        Platform in any respect; and (ii) modify these Terms of Use
        unilaterally.
      </p>
      <p>
        9.2. Use of Name or Markings: WFP Marks are the exclusive property of
        WFP and are protected under international law. Unauthorized use of the
        WFP Marks is prohibited. The WFP Marks may not be reproduced or used in
        any manner without WFP’s prior written permission. The mention or
        omission of specific companies, their products or brand names on the
        Platform does not imply any endorsement or judgement on the part of the
        WFP.
      </p>
      <p>
        9.3. Severability: Each provision of these Terms shall be considered
        severable such that if any provision conflicts with applicable law or it
        is determined that a provision of this Agreement is unenforceable, the
        parties intend that this Agreement be enforced as if the unenforceable
        provision were not present and that any partially valid and enforceable
        provision be enforced to the extent that it is enforceable.
      </p>
      <p>
        9.4. Entire Agreement: Should WFP accept an Offer, in part or in full,
        and decide to select a Winning Bid, these Terms, together with the Offer
        from the Bidder and the confirmation email by WFP about the Bidder’s
        successful selection shall constitute the binding and enforceable
        contract between WFP and the Winning Bidder. To the extent that there
        exists more than one language version of these Terms and there is any
        inconsistency between the versions, the English version shall always
        prevail.
      </p>
      <p>
        9.5. Contact: If you have any questions about this terms and conditions,
        the Platform and any questions related to any specific Auction please
        contact support.auctiondigitaloffice@wfp.org
      </p>
    </Container>
  );
};
