import { Agency, AssetFamily, OptionalNotNull } from "@utils";

interface PersonalData {
  fullName: string;
  email: string;
  phone: string;
}

export const INITIAL_VALUES = {
  isOnline: false,
  auctionImage: "",
  country: "",
  city: "",
  date: "",
  auctionEndDate: "",
  depositDate: "",
  timezone: "",
  currency: "",
  paymentAccount: "",
  paymentConditions: "",
  paymentConditionsFr: "",
  paymentConditionsEs: "",
  paymentConditionsPt: "",
  pickupDetails: "",
  clearanceInstructions: "",
  conversionRate: "",
  requiresOnlineDeposits: false,
  auctioneerFees: [] as {
    assets: Array<AssetFamily>;
    fee: OptionalNotNull<number>;
  }[],
  deposits: [] as {
    assets: Array<AssetFamily>;
    deposit: OptionalNotNull<number>;
  }[],
  agency: Agency.UNHCR,
  sealedBid: false,
  supervisor: undefined as OptionalNotNull<PersonalData>,
  focalPoint: undefined as OptionalNotNull<PersonalData>,
  representative: undefined as OptionalNotNull<PersonalData>,
  unhcrServicesFee: undefined as OptionalNotNull<number>,
  nonWfpLots: undefined as OptionalNotNull<number>,
  auctioneerInvoice: undefined as OptionalNotNull<number>,
  sendEmailBidWasOutbid: true,
  sendEmailBidderWon: true,
  emailLanguage: "",
  enableBankTransfer: false,
  enableOnlinePayment: false,
  enableDepositBankTransfer: false,
  enableDepositOnlinePayment: false,
};
