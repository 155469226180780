export const INITIAL_VALUES = {
  lot: "",
  reservePrice: "",
  name: "",
};

export const ONLINE_INITIAL_VALUES = {
  lot: "",
  name: "",
  assetFamily: "",
  startPrice: "",
  minStep: "",
  reservePrice: "",
  customClearance: false,
  brand: "",
  model: "",
  country: "",
  driverSide: "",
  yearOfMake: "",
  mileage: "",
  engine: "",
  transmission: "",
  inspectionReport: undefined,
  description: "",
  photo: undefined,
  video: "",
};
