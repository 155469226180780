import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;
`;

export const PrivacyPolicyEs = (): React.ReactElement => {
  return (
    <Container>
      <h2>Subastas en Línea del PMA</h2>
      <h3>Política de Privacidad</h3>
      <p>
        Esta Política de Privacidad regula la recogida, uso y divulgación de
        datos personales por parte de la herramienta de subastas en línea (“la
        Plataforma”) del Programa Mundial de Alimentos, un Servicio Compartido
        Global impulsado por el Programa Mundial de Alimentos (“PMA”) donde las
        Naciones Unidas, ONGs, entidades gubernamentales, humanitarias y de
        desarrollo pueden participar en una subasta.
      </p>

      <h3>Introducción</h3>
      <p>
        El PMA se compromete a proteger la privacidad y confidencialidad de los
        datos personales de sus usuarios. Esta política describe cómo
        recopilamos, utilizamos y salvaguardamos los datos personales
        proporcionados por los usuarios al acceder y utilizar la Plataforma.
      </p>

      <h3>Recogida y uso de datos personales</h3>
      <p>
        El PMA, situado en Via Cesare Giulio Viola 68/70 – 00148 Roma, Italia,
        es el responsable del tratamiento de los datos personales proporcionados
        en la Plataforma. La navegación en la Plataforma no requiere que el
        Usuario proporcione datos personales al PMA. Sin embargo, los Usuarios
        deben registrarse en la Plataforma para hacer una oferta o pujar por
        artículos o lotes del PMA.
      </p>
      <p>El PMA, como responsable del tratamiento, procesará:</p>
      <p>
        (i) Los datos de identificación y contacto del usuario para gestionar su
        registro en la Plataforma y, una vez que haya hecho una oferta o puja,
        informarle sobre el estado de la misma; y
      </p>
      <p>
        (ii) La información vinculada a una oferta o puja para ejecutar el
        proceso de subasta y decidir su adjudicación.
      </p>
      <p>
        Al acceder a la Plataforma, cierta información como direcciones de
        protocolo de Internet (IP), navegación a través de la Plataforma, el
        navegador utilizado y el tiempo empleado, junto con otra información
        similar, se almacenará en nuestros servidores y en un software de
        análisis de terceros. Esta información se recopila a través de cookies o
        tecnologías similares y se procesa para comprender mejor las
        preferencias de los visitantes de la Plataforma y mejorar su
        experiencia. Esta información nunca se conecta con ningún identificador
        directo que los visitantes nos proporcionen. Los usuarios pueden
        utilizar la configuración de su navegador para rechazar el uso de
        cookies y tecnologías similares que no son esenciales para el correcto
        funcionamiento de la Plataforma.
      </p>

      <h3>Retención</h3>
      <p>
        El PMA conservará los datos personales durante el tiempo que sea
        estrictamente necesario según nuestras regulaciones y normas internas y,
        en cualquier caso, por un período máximo de 6 años. Después de este
        período, los datos personales serán eliminados o anonimizados
      </p>

      <h3>Divulgación a terceros</h3>
      <p>
        El PMA no divulga información personal a terceros, excepto cuando sea
        estrictamente necesario para facilitar los servicios solicitados y de
        acuerdo con los propósitos mencionados anteriormente (por ejemplo,
        proveedores de tecnología que apoyan la Plataforma).
      </p>

      <h3>Cambios en la Política de Privacidad</h3>
      <p>
        La Política de Privacidad de la Plataforma puede ser actualizada
        periódicamente. Es aconsejable revisar esta política periódicamente para
        mantenerse informado sobre cómo se protegen los datos personales.
      </p>

      <h3>Privilegios e Inmunidades</h3>
      <p>
        Nada en lo relacionado con esta política de privacidad o este servicio
        se considerará una renuncia a cualquiera de los privilegios e
        inmunidades del PMA bajo la Convención sobre los Privilegios e
        Inmunidades de las Naciones Unidas y la Convención sobre los Privilegios
        e Inmunidades de los Organismos Especializados.
      </p>

      <h3>Derechos de Protección de Datos y Contacto</h3>
      <p>
        Las preguntas y/o solicitudes para acceder, rectificar o eliminar los
        datos personales, u oponerse a una actividad de tratamiento, pueden ser
        enviadas a support.auctiondigitaloffice@wfp. org WFP Online Auctions
      </p>
    </Container>
  );
};
