import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;

  a {
    color: #858bff;
  }
`;

export const TermsAndConditionsEs = () => {
  return (
    <Container>
      <h1>
        Términos de Uso Herramienta de Subastas en línea del Programa Mundial de
        Alimentos
      </h1>
      <p>
        Estos términos de uso ("Términos") regirán el uso y la participación en
        la herramienta de subastas en línea del Programa Mundial de Alimentos
        ("Plataforma") entre el usuario de la Plataforma ("Usuario") y el
        Programa Mundial de Alimentos ("PMA"). Al acceder o utilizar la
        Plataforma y sus servicios, así como al realizar cualquier transacción
        en la misma, usted acepta cumplir con y quedar sujeto a los presentes
        Términos.
      </p>

      <h2>1. Definiciones</h2>
      <ul>
        <li>
          Subasta" se refiere al proceso mediante el cual los Usuarios realizan
          ofertas sobre artículos listados en la Plataforma.
        </li>
        <li>
          "Oferente" se refiere a un Usuario que realiza Ofertas en Lotes
          listados en la Plataforma, debe ser mayor de 18 años y capaz de
          celebrar contratos legalmente vinculantes.
        </li>
        <li>
          "Incremento de la Oferta" o "Paso de oferta" se refiere a un aumento
          mínimo predefinido en el monto de la Oferta que el Oferente puede
          hacer para realizar una Oferta por un Lote específico.
        </li>
        <li>
          "Lote" se refiere a un artículo individual o grupo de artículos
          ofrecidos para ser subastados por el PMA a través de la Plataforma.
          Abarca toda la información, incluyendo pero no limitado a
          descripciones de texto, imágenes, material audiovisual, montos de
          oferta inicial, Precios de Reserva, duración y cualquier otro término
          o condición adicional especificado por el PMA y que se presenten en la
          Plataforma en relación con la Subasta.
        </li>
        <li>
          "Oferta" o "Puja" se refiere a una oferta o propuesta realizada por un
          Oferente en la Plataforma con el fin de comprar un Lote subastado por
          el PMA.
        </li>
        <li>
          "Plataforma" se refiere a la aplicación móvil de la Herramienta de
          Subastas en línea del Programa Mundial de Alimentos.
        </li>
        <li>
          "Precio de Reserva" se refiere a un monto mínimo predefinido y no
          revelado para el cual el PMA puede cerrar un Lote.
        </li>
        <li>
          "Requisitos" refieren a cualquier requisito legal o regulatorio,
          incluyendo pero no limitado a licencias, permisos o registros a los
          que ciertos artículos subastados en la Plataforma pueden estar
          sujetos.
        </li>
        <li>
          "Usuario" se refiere a la persona o entidad que utiliza la Plataforma.
        </li>
        <li>
          "Cuenta de Usuario" se refiere a la cuenta perteneciente al Usuario
          utilizada para acceder a la Plataforma.
        </li>
        <li>
          "Oferta Ganadora" se refiere a la Oferta identificada por el PMA como
          la Oferta a la que se le adjudica el Lote.
        </li>
        <li>
          "Oferente Ganador" se refiere al Oferente que realiza la Oferta
          Ganadora aceptada por el PMA.
        </li>
        <li>
          "Marcas PMA" se refiere al nombre, emblema y abreviatura del Programa
          Mundial de Alimentos.
        </li>
      </ul>

      <h2>2. Uso de la Plataforma</h2>
      <p>
        2.1. Normas internas y Cuenta de Usuario: El Usuario estará sujeto a
        todas las normas y regulaciones aplicables del PMA con respecto al uso
        interno de los recursos en línea del PMA. Para acceder a los servicios
        de la Plataforma, el Usuario debe utilizar su dirección de correo
        electrónico corporativa del PMA y sus credenciales. El Usuario acepta
        acceder a la Plataforma únicamente utilizando su propia cuenta
        corporativa. Nada en estos Términos de Uso se considerará que confiere
        derechos o beneficios a terceros.
      </p>
      <p>
        2.2. Suspensión y terminación: El PMA se reserva el derecho de suspender
        o terminar el acceso del Usuario a su discreción, incluso por
        violaciones a estos Términos, en particular en caso de que el Usuario
        realice Ofertas sin la intención de comprar y recoger Lotes dentro del
        tiempo definido o por cualquier otra razón. Los derechos y obligaciones
        del Usuario que resulten de una Oferta presentada en relación con un
        Lote no se verán afectados por ninguna modificación, suspensión o cierre
        de la Cuenta de Usuario.
      </p>

      <h2>3. Participación para ofertar</h2>
      <p>
        3.1. Invitación del PMA para Ofertar: La exhibición o disponibilidad de
        artículos y Lotes en la Plataforma no constituye una oferta vinculante o
        ejecutable por parte del PMA o bien, un contrato entre el PMA y el
        Oferente. Es una solicitud no vinculante de oferta sin obligación para
        el PMA de aceptar ninguna Oferta realizada después de la Invitación a
        ofertar, incluso si dicha Oferta cumple plenamente con todos los
        requisitos. Con respecto a la adjudicación del Lote, todos los Lotes
        para subasta están sujetos a un Precio de Reserva establecido por el
        PMA. El PMA no está obligado a revelar el Precio de Reserva y se reserva
        el derecho de rechazar cualquier Oferta y abstenerse de adjudicar un
        Lote si la Oferta no cumple con el Precio de Reserva.
      </p>
      <p>
        3.2. Cancelación de Listados: El PMA se reserva el derecho de cancelar
        en cualquier momento el listado y retirar la adjudicación en su
        totalidad o en parte por cualquier motivo, incluido en el caso de que el
        Oferente Ganador no pueda proporcionar el pago completo dentro de los 5
        días hábiles después del final de la Subasta.
      </p>
      <p>
        3.3. Oferta del Oferente: El Oferente acepta y reconoce que una Oferta
        constituye un compromiso legalmente vinculante para sí mismo para la
        compra del Lote, en caso que su Oferta sea identificada por el PMA como
        la Oferta Ganadora, de acuerdo con las reglas y procedimientos de la
        subasta realizada en la Plataforma. La Oferta realizada por el Oferente
        indicará el monto monetario específico que el Oferente ofrece pagar por
        el artículo. Para evitar dudas, cualquier tarifa adicional aplicada por
        el banco del Oferente, impuestos y cualquier otro cargo relacionado
        serán asumidos únicamente por el Oferente..
      </p>

      <h2>4. Transacciones</h2>
      <p>
        4.1. Instrucciones de Pago: Tras la adjudicación, el Oferente Ganador
        será informado mediante un correo electrónico a la dirección de correo
        electrónico del Usuario; dicho correo incluirá las instrucciones de pago
        pertinentes. El uso de la Plataforma es gratuito. Cualquier pago al PMA
        debe realizarse dentro de los 5 días a partir de la fecha de
        adjudicación, mediante una transferencia bancaria. Cualquier cargo
        aplicado por el banco en relación con esta transferencia será cubierto
        por el Oferente.
      </p>
      <p>
        4.2. Entrega: Una vez recibido el pago, el PMA informará al Oferente
        Ganador las instrucciones para la recogida. Por lo tanto, el PMA pondrá
        el Artículo a disposición y accesible para que el Oferente Ganador lo
        recoja en las instalaciones del PMA previamente indicada en la
        Plataforma, sujeta a la presentación de un documento formal de
        identificación.
      </p>
      <p>
        4.3. Devoluciones y Reembolsos: El PMA no acepta la devolución de ningún
        artículo y no se responsabiliza por ningún reembolso al Oferente
        Ganador.
      </p>
      <p>
        4.4. Diligencia debida del Oferente: El Oferente Ganador reconoce que
        ciertos artículos subastados en la Plataforma pueden estar sujetos a
        requisitos legales o regulatorios, incluidos pero no limitados a
        licencias, permisos o registros ("Requisitos") que deben obtenerse para
        la posesión, uso o propiedad legal del artículo. Es responsabilidad
        exclusiva del Oferente Ganador investigar e identificar cualquier
        Requisito aplicable asociado con un artículo antes de realizar una
        Oferta. Al participar en la Subasta, el Oferente Ganador confirma que ha
        realizado la diligencia debida necesaria para determinar si algún
        Requisito se aplica al artículo por el que está ofertando o que ha
        adquirido.
      </p>

      <h2>5. Responsabilidad</h2>
      <p>
        5.1. Garantía Limitada y Renuncias: Por el presente, el PMA renuncia
        expresamente y declara que los artículos para subasta se entregan "tal
        cual se encuentran" y "según su disponibilidad" sin ningún tipo de
        garantía expresa o implícita. Esto incluye, pero no se limita a,
        garantías de comerciabilidad, idoneidad para un propósito particular o
        no infracción. El PMA no se responsabiliza de la condición, calidad,
        autenticidad, legalidad o idoneidad de los artículos listados para
        subasta. En la mayor medida permitida por la ley, el PMA no será
        responsable de ningún daño directo, indirecto, incidental, consecuente,
        especial o punitivo, ni de ninguna pérdida de beneficios o ingresos, ya
        sea incurrida directa o indirectamente, de buena voluntad u otras
        pérdidas intangibles derivadas del uso de cualquiera de los artículos
        adquiridos por el Usuario y de cualquier transacción realizada en la
        Plataforma.
      </p>
      <p>
        5.2. Exención de responsabilidad de la Plataforma: El uso de la
        Plataforma y cualquiera de las funciones es bajo el propio riesgo del
        Usuario. La Plataforma, su contenido y características se proporcionan
        "tal cual" y "según disponibilidad" sin garantía de ningún tipo, ya sea
        expresa o implícita, incluidas, sin limitación, garantías de
        comerciabilidad, idoneidad para un propósito particular y no infracción.
        El PMA específicamente no hace ninguna garantía o representación en
        cuanto a la exactitud o integridad de la información presentada en la
        Plataforma. El PMA agrega, cambia, mejora o actualiza periódicamente la
        Plataforma, los materiales y las características de la Plataforma sin
        previo aviso. El Usuario reconoce y acepta específicamente que el PMA no
        es responsable de la conducta de ningún Usuario.
      </p>

      <h2>6. Protección de Datos</h2>
      <p>
        El uso de algunos servicios disponibles en la Plataforma pueden requerir
        el tratamiento de datos personales. El PMA tratara los datos personales
        conforme a sus propias normas y regulaciones internas tal y como se
        describe en la Politica de Privacidad.
      </p>

      <h2>7. Resolución de Disputas</h2>
      <p>
        7.1. Estos Términos de Uso y cualquier disputa que surja de ellos, así
        como cualquier transacción realizada en virtud de los mismos, serán
        exclusivamente regulados por los principios generales del derecho
        comercial internacional, con exclusión cualquier sistema legal nacional.
      </p>
      <p>
        7.2. Cualquier disputa, controversia o reclamo que surja de o esté
        relacionado con estos Términos de Uso o su incumplimiento, terminación o
        invalidez, o con el uso de los sitios, los Materiales y las Funciones
        del Sitio por parte del Usuario, o cualquier contenido disponible en
        ellos y sus términos de uso particulares, se resolverá mediante
        arbitraje de acuerdo con las Reglas de Arbitraje de la CNUDMI vigentes
        en ese momento. El tribunal arbitral, que estará compuesto por un (1)
        árbitro, estará facultado para ordenar: (i) la devolución o destrucción
        de bienes o cualquier propiedad, ya sea tangible o intangible, o de
        cualquier información confidencial proporcionada en virtud de este
        acuerdo; (ii) la terminación del mismo; o (iii) que se tomen otras
        medidas de protección con respecto a los bienes, servicios o cualquier
        otra propiedad, ya sea tangible o intangible, o de cualquier información
        confidencial proporcionada en virtud del presente, según corresponda,
        todo de acuerdo con la autoridad del tribunal arbitral conforme al
        Artículo 26 ('Medidas Provisionales') y al Artículo 34 ('Forma y Efecto
        del Laudo') de las Reglas de Arbitraje de la CNUDMI. El tribunal
        arbitral no tendrá autoridad para otorgar daños punitivos. El PMA y el
        Usuario estarán obligados por cualquier laudo arbitral emitido como
        resultado de dicho arbitraje como la adjudicación final de cualquier
        Disputa. El idioma de dichos procedimientos será el inglés y el lugar de
        arbitraje será Roma.
      </p>

      <h2>8. Privilegios e Inmunidades</h2>
      <p>
        Nada de lo dispuesto en el presente Términos de Uso o en cualquier
        documento suscrito en relación con el presente Términos constituirá una
        renuncia, expresa o tácita, por parte del PMA, las Naciones Unidas y la
        Organización de las Naciones Unidas para la Alimentación y la
        Agricultura de cualquiera de las prerrogativas e inmunidades que se les
        reconocen conforme la Convención sobre Prerrogativas e Inmunidades de
        las Naciones Unidas de 1946, la Convención sobre Prerrogativas e
        Inmunidades de los Organismos Especializados de 1947, el derecho
        internacional y demás acuerdos y/o convenios internacionales o
        nacionales y bajo la ley local.
      </p>

      <h2>9. Miscelánea</h2>
      <p>
        9.1.Modificaciones a los Términos: El PMA se reserva el derecho
        exclusivo, a su sola discreción, de (i) alterar, limitar o discontinuar
        el acceso a la Plataforma en cualquier aspecto; y (ii) modificar
        unilateralmente estos Términos.
      </p>
      <p>
        9.2. Uso de Nombre o Marcas: Las Marcas del PMA son propiedad exclusiva
        del PMA y están protegidas bajo derecho internacional. El uso no
        autorizado de las Marcas del PMA está prohibido. Las Marcas del PMA no
        pueden ser reproducidas ni utilizadas de ninguna manera sin el permiso
        previo por escrito del PMA. La mención u omisión de empresas
        específicas, sus productos o nombres de marca en la Plataforma no
        implica ningún respaldo o juicio por parte del PMA.
      </p>
      <p>
        9.3. Separabilidad: Cada disposición de estos Términos se considerará
        separable de modo que si alguna disposición entra en conflicto con la
        ley aplicable o se determina que es inaplicable, las partes tienen la
        intención de que este Acuerdo se aplique como si la disposición
        inaplicable no estuviera presente y que cualquier disposición
        parcialmente válida y aplicable se aplique en la medida en que sea
        aplicable.
      </p>
      <p>
        9.4. Acuerdo Completo: En caso de que el PMA acepte una Oferta en parte
        o en su totalidad y decida seleccionar una Oferta Ganadora, estos
        Términos, junto con la Oferta del Oferente y el correo electrónico de
        confirmación del PMA sobre la selección exitosa del Oferente,
        constituirán el contrato vinculante y ejecutable entre el PMA y el
        Oferente Ganador. En la medida en que existan más de una versión en
        idioma de estos Términos y haya alguna inconsistencia entre las
        versiones, prevalecerá siempre la versión en inglés.
      </p>
      <p>
        9.5. Contacto: Si tiene alguna pregunta sobre estos términos y
        condiciones, la Plataforma o cualquier pregunta relacionada con una
        Subasta específica, póngase en contacto con
        support.auctiondigitaloffice@wfp.org.
      </p>
    </Container>
  );
};
